;
"use strict";

(function() {
	function resizeMainVideo() {
		const mainVideoElement = document.querySelector('.js-main-video');
		if (mainVideoElement) {
			function setHeight() {
				const width = mainVideoElement.offsetWidth > 1200 ? 1200 : mainVideoElement.offsetWidth;
				const part = width / 16;
				mainVideoElement.style.height = `${part * 9}px`;
			}

			setHeight();
			window.addEventListener('resize', setHeight);
		}
	}

	function toggle(element, cb = () => {}) {
		if ([...element.classList].includes('active')) {
			element.classList.remove('active');
			cb('');
		} else {
			element.classList.add('active');
			cb('active');
		}
	}

	function mobileMenu() {
		const hamburger = document.querySelector('.js-hamburger');
		if (hamburger) {
			hamburger.addEventListener('click', () => {
				toggle(hamburger, status => {
					document.body.style.overflow = status === 'active' ? 'hidden' : 'auto';
				});
			});
		}
	}

	function sliderAdvantages() {
		$('#js-advantages-slider').slick({
			infinite: true,
			dots: true,
  			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
	}

	function lazyLoadVideo() {
		const videoElement = document.querySelector('video');
		if (videoElement) {
			for (let item in videoElement.children) {
				const sourceElement = videoElement.children[item];
				if (typeof sourceElement.tagName === "string" && sourceElement.tagName === "SOURCE") {
					sourceElement.src = sourceElement.getAttribute('data-src');
				}
			}
			videoElement.load();
		}
	}

	document.addEventListener("DOMContentLoaded", function() {
		resizeMainVideo();
		mobileMenu();
		sliderAdvantages();
		lazyLoadVideo();
	});
})();